.page.resources {
  max-inline-size: 100%;
}
.filter-form {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: var(--size-2);
  margin-block-start: var(--size-6);

  & label {
    inline-size: 100%;
    position: relative;
  }
}

.filter-form fieldset {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: var(--size-2);
  inline-size: 100%;
  padding: 0;
  transition: opacity .25s ease-in-out;
}
.filter-form fieldset[disabled="true"] {
  opacity: .5;

  & select {
    cursor: not-allowed;
  }
}

.filter-form button {
  box-shadow: none;
  margin-block-end: var(--size-1);
}
.filter-form select {
  width: 100%;
}

.keyword-search {
  margin-block-start: var(--size-8);

  > input {
    margin-block-start: var(--size-2);
    padding: var(--size-3)
  }
}

@media screen and (min-width: 48rem) {
  .filter-form,
  .filter-form fieldset {
    flex-direction: row;
  }
  .filter-form label {
    inline-size: 33%;
  }
}
