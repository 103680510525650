.contact.page {
  inline-size: min(100%, 65ch);
}

/* Contact Form
--------------------------------------------------*/
[name="contact-form"] {
  background-color: hsl(0 0% 100% / .75);
  max-inline-size: 75rem;
  margin-block-start: var(--size-6);
  margin-inline: auto;
  padding: var(--size-1) var(--size-2);



  .Inputfields {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--size-6);

    > div {
      grid-column: span 2;
    }

    & div:nth-child(2),
    & div:nth-child(3),
    & div:nth-child(6),
    & div:nth-child(7),
    & div:nth-child(8),
    & div:nth-child(9),
    & div:nth-child(13),
    & div:nth-child(14) {
      grid-column: span 1;
    }
  }



  & label {
    display: inline-block;
    /* @apply inline-block mb-2; */
  }
}

.InputfieldSelect {
  padding: 0;
  position: relative;
}
[name="contact-form"] .InputfieldSelect::after {
  top: 65%;
}

.InputfieldContent {
  margin-block-start: var(--size-2);
}
.InputfieldHidden,
.InputfieldHidden > * {
  opacity: 0;
  height: 0;
  width: 0;
}
.Inputfield_organization_structure {
  float: left;
  height: 0;
  left: -666rem;
  position: absolute;
  width: 0;
}

.Inputfield_org_structure {
  display: none;
}

@media screen and (min-width: 48rem) {
  [name="contact-form"] {
    /* padding: var(--size-6) var(--size-12); */
  }
  [name="contact-form"] .Inputfields {
    display: grid;
  }
}
