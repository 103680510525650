.team-list {
  display: flex;
  flex-direction: column;
  gap: var(--size-2);
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.team-list li {
  flex-grow: 1;
  inline-size: 60%;
  margin: 0;
  margin-inline: auto;
}
.team-list li img {
  inline-size: 100%;
  object-fit: cover;
}
.team-list li h3 {
  font-size: 1.25rem;
  font-weight: 400;
  margin-block-start: var(--size-2);
}

@media screen and (min-width: 49.375rem) {
  .team-list {
    flex-direction: row;
  }
}
