.chapters > * {
  block-size: fit-content;
}
.chapter {
  isolation: isolate;
  position: relative;
}

.overlay-magenta {
  background-color: var(--magenta);
  inset: 0;
  position: absolute;
  opacity: .3;
}
.overlay-magenta ~ h3 {
  background-color: var(--brand);
}

.overlay-mint {
  background-color: var(--brand);
  inset: 0;
  position: absolute;
  opacity: .3;
}
.overlay-mint ~ h3 {
  background-color: var(--magenta);
}

.chapter h3 {
  border-radius: 0 4px 0 4px;
  bottom: 0;
  color: white;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 1px;
  padding: var(--size-4);
  position: absolute;
}

.chapter img {
  border-radius: 4px;
  inline-size: 100%;
}

.chapter a {
  inset: 0;
  position: absolute;

  & span { display: none;}
}

.chapter main table {
  margin-inline: auto;
}

.page-content {
  margin-block-start: var(--size-8);
}

.page-content p:has(img) {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-2);
}
.page-content p img {
  object-fit: cover;
  flex-shrink: 0;
  flex-grow: 1;
}

.page-content img {
  border-radius: var(--size-1);
}
