/* Table
--------------------------------------------------*/
table {
  background-color: hsl(0 0% 100% / 0.6);
  border-collapse: collapse;
  border: 1px solid var(--gray-light);
  border-radius: var(--size-2);
  display: block;
  isolation: isolate;
  overflow-x: auto;
  position: relative;
  text-align: left;
  width: 100%;
}
th,
td {
  padding-block: 1rem;
  text-align: left;
}
tr {
  border-bottom: 1px solid var(--gray-light);
  border-top: 1px solid var(--gray-light);
}
thead,
tfoot {
  background: #fff;
  z-index: 1;
}
thead {
  position: sticky;
  top: 0;
}
tfoot {
  position: sticky;
  bottom: 0;
  border-top: 2px solid #ccc;
}
th {
  position: sticky;
  left: 0;
  /* box-shadow: 42px 6px 1px rgb(0 0 0 / .5); */
}
tbody th {
  background: white;
  min-width: 20ch;
}
thead th:first-child, tfoot th:first-child {
  background: #fff;
  z-index: 2;
}
:where(table tr:hover td),
:where(tbody tr:nth-child(even):hover td) {
  @media (prefers-color-scheme: light) {
    background-color: transparent;
  }
}
