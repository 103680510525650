.home {
  --gap: var(--size-4);
  max-inline-size: 55ch;
  margin-inline: auto;

  & h3 {
    margin-block-start: var(--size-6);
  }

  & p:first-of-type {
    text-align: left;
  }
}
