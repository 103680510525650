
/* Raster grid subsystem (rsms.me/raster) */

/* .cell -- cell or column */
.grid > .cell {
  display: block;
  appearance: none;
  -webkit-appearance: none;
}
.grid,
.grid > .grid {
  display: grid;
  --grid-tc: repeat(4, 1fr);
  grid-template-columns: var(--grid-tc);
  --grid-cs: 1; /* column start */
  --grid-ce: -1; /* column end */
  --grid-gap-x: 0;
  --grid-gap-y: 0;
  gap: var(--grid-gap-x) var(--grid-gap-y);
}

.grid[data-gap="0"] {
  --grid-gap-x: 0;
  --grid-gap-y: 0;
}
.grid[data-gap="1"] {
  --grid-gap-x: 0.5rem;
  --grid-gap-y: 0.5rem;
}
.grid[data-gap="2"] {
  --grid-gap-x: 1rem;
  --grid-gap-y: 1rem;
}
.grid[data-gap="3"] {
  --grid-gap-x: 1.5rem;
  --grid-gap-y: 1.5rem;
}
.grid[data-gap="4"] {
  --grid-gap-x: 2rem;
  --grid-gap-y: 2rem;
}
.grid[data-gap-x="0"] {
  --grid-gap-x: 0;
}
.grid[data-gap-x="1"] {
  --grid-gap-x: 0.5rem;
}
.grid[data-gap-x="2"] {
  --grid-gap-x: 1rem;
}
.grid[data-gap-x="3"] {
  --grid-gap-x: 1.5rem;
}
.grid[data-gap-x="4"] {
  --grid-gap-x: 2rem;
}
.grid[data-gap-y="0"] {
  --grid-gap-y: 0;
}
.grid[data-gap-y="1"] {
  --grid-gap-y: 0.5rem;
}
.grid[data-gap-y="2"] {
  --grid-gap-y: 1rem;
}
.grid[data-gap-y="3"] {
  --grid-gap-y: 1.5rem;
}
.grid[data-gap-y="4"] {
  --grid-gap-y: 2rem;
}

.grid[data-columns="1"] {
  --grid-tc: repeat(1, 1fr);
}
.grid[data-columns="2"] {
  --grid-tc: repeat(2, 1fr);
}
.grid[data-columns="3"] {
  --grid-tc: repeat(3, 1fr);
}
.grid[data-columns="4"] {
  --grid-tc: repeat(4, 1fr);
}
.grid[data-columns="5"] {
  --grid-tc: repeat(5, 1fr);
}
.grid[data-columns="6"] {
  --grid-tc: repeat(6, 1fr);
}
.grid[data-columns="7"] {
  --grid-tc: repeat(7, 1fr);
}
.grid[data-columns="8"] {
  --grid-tc: repeat(8, 1fr);
}
.grid[data-columns="12"] {
  --grid-tc: repeat(12, 1fr);
}

/* span=start... */
.grid > .cell[data-span^="1"] {
  --grid-cs: 1;
}
.grid > .cell[data-span^="2"] {
  --grid-cs: 2;
}
.grid > .cell[data-span^="3"] {
  --grid-cs: 3;
}
.grid > .cell[data-span^="4"] {
  --grid-cs: 4;
}
.grid > .cell[data-span^="5"] {
  --grid-cs: 5;
}
.grid > .cell[data-span^="6"] {
  --grid-cs: 6;
}
.grid > .cell[data-span^="7"] {
  --grid-cs: 7;
}
.grid > .cell[data-span^="8"] {
  --grid-cs: 8;
}

/* span=...+width, span=...-end */
.grid > .cell[data-span$="+1"],
.grid > .cell[data-span="1"] {
  --grid-ce: 1;
}
.grid > .cell[data-span$="+2"],
.grid > .cell[data-span$="-1"],
.grid > .cell[data-span="2"] {
  --grid-ce: 2;
}
.grid > .cell[data-span$="+3"],
.grid > .cell[data-span$="-2"],
.grid > .cell[data-span="3"] {
  --grid-ce: 3;
}
.grid > .cell[data-span$="+4"],
.grid > .cell[data-span$="-3"],
.grid > .cell[data-span="4"] {
  --grid-ce: 4;
}
.grid > .cell[data-span$="+5"],
.grid > .cell[data-span$="-4"],
.grid > .cell[data-span="5"] {
  --grid-ce: 5;
}
.grid > .cell[data-span$="+6"],
.grid > .cell[data-span$="-5"],
.grid > .cell[data-span="6"] {
  --grid-ce: 6;
}
.grid > .cell[data-span$="+7"],
.grid > .cell[data-span$="-6"],
.grid > .cell[data-span="7"] {
  --grid-ce: 7;
}
.grid > .cell[data-span$="+8"],
.grid > .cell[data-span$="-7"],
.grid > .cell[data-span="8"] {
  --grid-ce: 8;
}
.grid > .cell[data-span$="-8"] {
  --grid-ce: 9;
}

/* connect vars */
.grid > .cell[data-span] {
  grid-column-end: span var(--grid-ce);
}
.grid > .cell[data-span*="+"],
.grid > .cell[data-span*="-"],
.grid > .cell[data-span*=".."] {
  grid-column-start: var(--grid-cs);
}
.grid > .cell[data-span*="-"],
.grid > .cell[data-span*=".."] {
  grid-column-end: var(--grid-ce);
}
.grid > .cell[data-span="row"] {
  grid-column: 1 / -1;
}

/* for window width <= 600 */
@media only screen and (min-width: 40rem) and (max-width: 48rem) {
  .grid[data-columns-s="1"] {
    --grid-tc: repeat(1, 1fr);
  }
  .grid[data-columns-s="2"] {
    --grid-tc: repeat(2, 1fr);
  }
  .grid[data-columns-s="3"] {
    --grid-tc: repeat(3, 1fr);
  }
  .grid[data-columns-s="4"] {
    --grid-tc: repeat(4, 1fr);
  }
  .grid[data-columns-s="5"] {
    --grid-tc: repeat(5, 1fr);
  }
  .grid[data-columns-s="6"] {
    --grid-tc: repeat(6, 1fr);
  }
  .grid[data-columns-s="7"] {
    --grid-tc: repeat(7, 1fr);
  }
  .grid[data-columns-s="8"] {
    --grid-tc: repeat(8, 1fr);
  }

  /* span-s=start... */
  .grid > .cell[data-span-s^="1"] {
    --grid-cs: 1;
  }
  .grid > .cell[data-span-s^="2"] {
    --grid-cs: 2;
  }
  .grid > .cell[data-span-s^="3"] {
    --grid-cs: 3;
  }
  .grid > .cell[data-span-s^="4"] {
    --grid-cs: 4;
  }
  .grid > .cell[data-span-s^="5"] {
    --grid-cs: 5;
  }
  .grid > .cell[data-span-s^="6"] {
    --grid-cs: 6;
  }
  .grid > .cell[data-span-s^="7"] {
    --grid-cs: 7;
  }
  .grid > .cell[data-span-s^="8"] {
    --grid-cs: 8;
  }

  /* span-s=...+width, span-s=...-end */
  .grid > .cell[data-span-s$="+1"],
  .grid > .cell[data-span-s="1"] {
    --grid-ce: 1;
  }
  .grid > .cell[data-span-s$="+2"],
  .grid > .cell[data-span-s$="-1"],
  .grid > .cell[data-span-s="2"] {
    --grid-ce: 2;
  }
  .grid > .cell[data-span-s$="+3"],
  .grid > .cell[data-span-s$="-2"],
  .grid > .cell[data-span-s="3"] {
    --grid-ce: 3;
  }
  .grid > .cell[data-span-s$="+4"],
  .grid > .cell[data-span-s$="-3"],
  .grid > .cell[data-span-s="4"] {
    --grid-ce: 4;
  }
  .grid > .cell[data-span-s$="+5"],
  .grid > .cell[data-span-s$="-4"],
  .grid > .cell[data-span-s="5"] {
    --grid-ce: 5;
  }
  .grid > .cell[data-span-s$="+6"],
  .grid > .cell[data-span-s$="-5"],
  .grid > .cell[data-span-s="6"] {
    --grid-ce: 6;
  }
  .grid > .cell[data-span-s$="+7"],
  .grid > .cell[data-span-s$="-6"],
  .grid > .cell[data-span-s="7"] {
    --grid-ce: 7;
  }
  .grid > .cell[data-span-s$="+8"],
  .grid > .cell[data-span-s$="-7"],
  .grid > .cell[data-span-s="8"] {
    --grid-ce: 8;
  }
  .grid > .cell[data-span-s$="-8"] {
    --grid-ce: 9;
  }

  /* connect vars */
  .grid > .cell[data-span-s] {
    grid-column-end: span var(--grid-ce);
  }
  .grid > .cell[data-span-s*="+"],
  .grid > .cell[data-span-s*="-"],
  .grid > .cell[data-span-s*=".."] {
    grid-column-start: var(--grid-cs);
  }
  .grid > .cell[data-span-s*="-"],
  .grid > .cell[data-span-s*=".."] {
    grid-column-end: var(--grid-ce);
  }
  .grid > .cell[data-span-s="row"] {
    grid-column: 1 / -1;
  }
}

/* for window width >= 1600 */
@media only screen and (min-width: 49rem) {
  .grid[data-columns-l="1"] {
    --grid-tc: repeat(1, 1fr);
  }
  .grid[data-columns-l="2"] {
    --grid-tc: repeat(2, 1fr);
  }
  .grid[data-columns-l="3"] {
    --grid-tc: repeat(3, 1fr);
  }
  .grid[data-columns-l="4"] {
    --grid-tc: repeat(4, 1fr);
  }
  .grid[data-columns-l="5"] {
    --grid-tc: repeat(5, 1fr);
  }
  .grid[data-columns-l="6"] {
    --grid-tc: repeat(6, 1fr);
  }
  .grid[data-columns-l="7"] {
    --grid-tc: repeat(7, 1fr);
  }
  .grid[data-columns-l="8"] {
    --grid-tc: repeat(8, 1fr);
  }

  /* span-l=start... */
  .grid > .cell[data-span-l^="1"] {
    --grid-cs: 1;
  }
  .grid > .cell[data-span-l^="2"] {
    --grid-cs: 2;
  }
  .grid > .cell[data-span-l^="3"] {
    --grid-cs: 3;
  }
  .grid > .cell[data-span-l^="4"] {
    --grid-cs: 4;
  }
  .grid > .cell[data-span-l^="5"] {
    --grid-cs: 5;
  }
  .grid > .cell[data-span-l^="6"] {
    --grid-cs: 6;
  }
  .grid > .cell[data-span-l^="7"] {
    --grid-cs: 7;
  }
  .grid > .cell[data-span-l^="8"] {
    --grid-cs: 8;
  }

  /* span-l=...+width, span-l=...-end */
  .grid > .cell[data-span-l$="+1"],
  .grid > .cell[data-span-l="1"] {
    --grid-ce: 1;
  }
  .grid > .cell[data-span-l$="+2"],
  .grid > .cell[data-span-l$="-1"],
  .grid > .cell[data-span-l="2"] {
    --grid-ce: 2;
  }
  .grid > .cell[data-span-l$="+3"],
  .grid > .cell[data-span-l$="-2"],
  .grid > .cell[data-span-l="3"] {
    --grid-ce: 3;
  }
  .grid > .cell[data-span-l$="+4"],
  .grid > .cell[data-span-l$="-3"],
  .grid > .cell[data-span-l="4"] {
    --grid-ce: 4;
  }
  .grid > .cell[data-span-l$="+5"],
  .grid > .cell[data-span-l$="-4"],
  .grid > .cell[data-span-l="5"] {
    --grid-ce: 5;
  }
  .grid > .cell[data-span-l$="+6"],
  .grid > .cell[data-span-l$="-5"],
  .grid > .cell[data-span-l="6"] {
    --grid-ce: 6;
  }
  .grid > .cell[data-span-l$="+7"],
  .grid > .cell[data-span-l$="-6"],
  .grid > .cell[data-span-l="7"] {
    --grid-ce: 7;
  }
  .grid > .cell[data-span-l$="+8"],
  .grid > .cell[data-span-l$="-7"],
  .grid > .cell[data-span-l="8"] {
    --grid-ce: 8;
  }
  .grid > .cell[data-span-l$="-8"] {
    --grid-ce: 9;
  }

  /* connect vars */
  .grid > .cell[data-span-l] {
    grid-column-end: span var(--grid-ce);
  }
  .grid > .cell[data-span-l*="+"],
  .grid > .cell[data-span-l*="-"],
  .grid > .cell[data-span-l*=".."] {
    grid-column-start: var(--grid-cs);
  }
  .grid > .cell[data-span-l*="-"],
  .grid > .cell[data-span-l*=".."] {
    grid-column-end: var(--grid-ce);
  }
  .grid > .cell[data-span-l="row"] {
    grid-column: 1 / -1;
  }
}

/* .debug can be added to a .grid to visualize its effective cells */
.grid.debug > * {
  --color: rgba(248, 110, 91, 0.3);
  background-image: linear-gradient(
    to bottom,
    var(--color) 0%,
    var(--color) 100%
  );
}
.grid.debug > :nth-child(6n + 2) {
  --color: rgba(103, 126, 208, 0.3);
}
.grid.debug > :nth-child(6n + 3) {
  --color: rgba(224, 174, 72, 0.3);
}
.grid.debug > :nth-child(6n + 4) {
  --color: rgba(77, 214, 115, 0.3);
}
.grid.debug > :nth-child(6n + 5) {
  --color: rgba(217, 103, 219, 0.3);
}
.grid.debug > :nth-child(6n + 6) {
  --color: rgba(94, 204, 211, 0.3);
}
.grid.debug > :nth-child(6n + 7) {
  --color: rgba(248, 110, 91, 0.3);
}
