.hero {
  background-color: var(--mint);
  padding-block: var(--size-8);
}
.book-cover {
  border-radius: .25rem;
  border: .75rem solid white;
  box-shadow: 0px 4px 36px 12px rgba(94, 178, 165, 0.46);
}

.hero-decor-before {
    inline-size: 100%;
    block-size: 2rem;
    background-color: var(--mint);
    padding: 1rem;
    position: relative;
    z-index: -1;
}
.hero-decor {
  position: relative;
  top: -5px;
  width: 100%;
  z-index: -1;
}

@media screen and (max-width: 61rem) {
  .hero {
    display: block;
    padding-block-start: var(--size-10);
  }
}
