.pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--size-2);
  justify-content: center;
  padding-block: var(--size-4);
}

.pagination a {
  padding: var(--size-4);
}
