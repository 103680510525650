/* Any HTML element requiring specific styling with broad reach: */
@import url('./element.table.css');
html {
  background-color: #fff;
  block-size: 100%;
}

body {
  background-position: top center;
  background-size: 100vh;
  color: var(--color);
  font-family: var(--primary-font);
  block-size: 100%;
}

h1,h2,h3,h4,h5,h6 {
  font-family: var(--font-sans);
  font-weight: bold;
}
h1 {
  font-size: 3.75rem;
  line-height: 1.4;
}
h2 {
  font-size: 2.25rem;
  line-height: 1.4;

  &:not(:first-child) {
    margin-block-start: var(--size-10);
  }
}

h3 {
  font-size: 1.5rem;
  line-height: 1.4;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h5 {
  font-size: 1.125rem;
  line-height: 1.4;
}


a:not([class]) {
  color: var(--link-color);
  text-decoration: underline;
  transition: all .3s;

  &:hover {
    color: var(--complementary);
    text-decoration: none;
  }
}


ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


main {
  min-block-size: 50%;
  inline-size: min(95vw, 65rem);
  margin-inline: auto;
  padding-block-end: var(--size-12);
  padding-inline: var(--size-4);

  & * {
    max-inline-size: unset;
  }
}

img, video {
  max-width: 100%;
}

video {
  aspect-ratio: 16 / 9;
  background-color: teal;
  border: 1px solid var(--gray-light);
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-3);
  margin-inline: auto;
  margin-block: var(--size-4);
  min-width: 100%;
  padding: var(--size-4);
}


/* Forms
--------------------------------------------------*/
select {
  appearance: none;
  border: 1px solid var(--gray-light);
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
  inline-size: 100%;
  padding: var(--size-3);
  position: relative;
}
.InputfieldSelect::after,
.selector {
  content: '';
  position: absolute;
  height: 0.65rem;
  width: 1.25rem;
  z-index: 1;
  background-color: var(--brand);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  top: 55%;
  transform: translateX(-60%);
  right: 0;
}

input[type="text"],
input[type="email"],
input[type="date"],
input[type="number"],
textarea {
  border: 1px solid #caced1;
  padding: var(--size-3) 0.675em;
  width: 100%;
}
input[type="date"] {
  padding: 0.675em 1em 0.675em 1em;
}

input, textarea {
  background-color: #fff;
}

a.btn,
button {
  --bg-color: var(--brand);
  --shadow-color: var(--brand);
  background-color: var(--bg-color);
  border-radius: 0.25rem;
  box-shadow: 3px 11px 30px -10px var(--shadow-color);
  color: var(--color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-sans);
  font-weight: 600;
  letter-spacing: .08ch;
  padding: var(--size-2) var(--size-4);
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease;
}
