.site-footer {
  background-color: var(--magenta);
  color: white;
  isolation: isolate;
  overflow: hidden;
  padding-block-end: var(--size-8);
  padding-block-start: var(--size-10);
  position: relative;
}

.site-footer .border {
  height: .5rem;
  background-color: var(--magenta-light);
  box-shadow: 0px 0px 30px 8px var(--magenta-dark);
  inline-size: 100%;
  position: absolute;
  top: 0;
}

.site-footer nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--size-4);
  justify-content: center;
  padding-inline: var(--size-4);
}

.site-footer nav a {
  color: white;
}

.site-footer .cta {
  position: relative;
}
.site-footer .cta a {
  --shadow-color: hsl(60 45% 24% / var(--color-opacity))
}

.site-footer .mandala {
  left: 50%;
  opacity: .2;
  position: absolute;
  top: 40%;
  transform: translateX(-50%);
  width: 600px;
  z-index: 0;
}

/* raise all content above the mandala */
.site-footer > * {
  z-index: 1;
}
