.cell:has(.testimonials) {
  display: flex;
  flex-direction: column;
  padding-inline: var(--size-4);
  position: relative;
}

.testimonials {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-block-start: calc(var(--size-6) * 2);
  margin-inline: auto;
  overflow: hidden;
  position: relative;
  max-inline-size: 50ch;
}
.testimonials figure {
  --x: 0%;
  display: block;
  position: relative;
  transform: translateX(var(--x));
  transition: all 0.5s;
  max-inline-size: 50ch;
}
.testimonials figure + figure {
  margin-block-start: calc(var(--size-6) * 2);
}

.testimonials blockquote {
  border: none;
  display: block;
  font-size: min(3vw, 1.5rem);
  padding: 0;
}
.testimonials figcaption {
  margin-block-start: var(--size-4);
}


.controls {
  display: none;
  gap: var(--size-4);
  margin-block-start: var(--size-2);


  & > button {
    --bg: var(--mint-lighter);
    background-color: var(--bg);
    border: none;
    border-radius: 666rem;
    height: 1rem;
    padding: .5rem;
    transition: all .3s;
    width: 1rem;

    & > span {
      display: none;
    }
  }

  & > button.active {
    --bg: white;
    padding: .25rem;
    transform: scale(1.3);
  }
}

.cta {
  margin-block-start: var(--size-8);
  inline-size: min(100%, 50ch);
  margin-inline: auto;
}
.cta p {
  font-size: min(3vw, 1.5rem);
}
.cta .btn {
  margin-block-start: var(--size-4);
}


@media screen and (min-width: 61rem) {
  .cell:has(.testimonials) {
    padding-inline: 0;
  }
  .testimonials {
    flex-direction: row;
    margin-block-start: 0;
    margin-inline: initial;
    inline-size: 100%;
  }
  .testimonials figure {
    inline-size: 100%;
    flex-shrink: 0;

    &:nth-child(2) {
      --x: 100%;
    }
    &:nth-child(3) {
      --x: 200%;
    }
  }
  .testimonials figure + figure {
    margin-block-start: 0;
  }
  .controls {
    display: flex;
  }
  .cta {
    margin-block-start: var(--size-4);
    margin-inline: initial;
  }
}
