/* Styles for structural objects like media objects, layouts such as grids, etc. */
@import 'grid.css';

.centered-block {
  --gap: var(--size-8);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  text-align: center;

  > * {
    margin-inline: auto;
  }
}

.intro-text {
  max-inline-size: 60ch;
}

.text-block {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
  max-inline-size: 60ch;
  margin-inline: auto;
  position: relative;
}

.Inputfields {
  list-style-type: none;
  padding: 0;
}


.resource-table {
  margin-top: var(--size-8);
}

[class*="overlay"] {
  block-size: 100%;
  inline-size: 100%;
  inset: 0;
  position: absolute;
}


.page {
  max-inline-size: 85ch;
  margin-block-start: var(--size-5);
}

.page ul {
  margin-inline-start: var(--size-6);

  > li + li {
    margin-block: var(--size-2);
  }
}

.stack {
  display: flex;
  flex-direction: column;
  gap: var(--size-4);
}

@media screen and (min-width: 61rem) {
  .page {
    margin-block-start: calc(var(--size-10) * -1);
  }
}
