/* Utility classes */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only {
  /* https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034 */
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;  /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            /* 3 */
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  /*
    Use in conjunction with .sr-only to only display content when it's focused.
    @note Useful for skip links
    @see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
    @note Based on a HTML5 Boilerplate technique, included in Bootstrap
    @note Fixed a bug with position: static on iOS 10.0.2 + VoiceOver
      @author Sylvain Pigeard
      @see https://github.com/twbs/bootstrap/issues/20732
  */
	clip: auto !important;
	-webkit-clip-path: none !important;
		clip-path: none !important;
	height: auto !important;
	margin: auto !important;
	overflow: visible !important;
	width: auto !important;
	white-space: normal !important;
}

/* :empty:not(img, .blurred-bg, .spacer, #drawer-toggle) {
  display: none;
} */

.blurred-bg {
  background: radial-gradient( circle at 50% 50%, rgb(255 255 255 / 50%) 0%, rgb(255 255 255 / 83%) 50%, rgb(255 255 255 / 0%) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .75;
    z-index: 0;
}


@media screen and (max-width: 48rem) {
  .hide-on-mobile {
    display: none;
  }

}
