/* @import "open-props/src/props.colors.css"; */
/* @import "open-props/src/props.animations.css"; */
/* @import "open-props/src/props.fonts.css"; */
@import "open-props/src/props.borders.css";
@import "open-props/src/props.shadows.css";
@import "open-props/src/props.sizes.css";

@font-face {
  font-family: 'Akshar Variable';
  src: url('../fonts/Akshar/Akshar-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/Merriweather-Light.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/Merriweather-Italic.ttf') format('truetype');
  font-style: italic;
}
@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/Merriweather-Bold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/Merriweather-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600;
}

/* Custom Properties && Overrides */
:root {
  --font-serif: 'Merriweather', serif;
  --font-sans: 'Akshar Variable', sans-serif;
  --primary-font: var(--font-serif);
  --color-opacity: 1;

  --brand: hsl(171 35% 53% / var(--color-opacity));
  --mint: hsl(171 53% 83% / var(--color-opacity));
  --mint-lighter: hsl(171 55% 92% / var(--color-opacity));
  --mint-lightest: hsl(171 50% 96% / var(--color-opacity));
  --yellow: hsl(60 81% 63% / var(--color-opacity));

  --magenta-light: hsl(314 65% 81% / var(--color-opacity));
  --magenta: hsl(314 35% 53% / var(--color-opacity));
  --magenta-dark: hsl(314 35% 41% / var(--color-opacity));

  --red: hsl(3 81% 63% / var(--color-opacity));

  --gray-dark: hsl(171 35% 23% / var(--color-opacity));
  --gray: hsl(171 5% 51% / var(--color-opacity));
  --gray-light: hsl(212 26% 88% / var(--color-opacity));

  --link-color: var(--red);
  --color: var(--gray-dark);

  --container-width: min(90vw, 76rem);
}

@media (prefers-color-scheme: dark) {

}
