/* UI components */
@import url('./component.nav.css');
@import url('./component.hero.css');
@import url('./component.testimonials.css');
@import url('./component.button.css');
@import url('./component.home.css');
@import url('./component.footer.css');
@import url('./component.chapter.css');
@import url('./component.contact.css');
@import url('./component.team.css');
@import url('./component.filter-form.css');
@import url('./component.pagination.css');
