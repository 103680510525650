/* Navigation
--------------------------------------------------*/
.drawer-toggle-label {
  background-color: var(--brand);
  border-radius: 4px;
  cursor: pointer;
  display:flex;
  flex-direction: column;
  padding: .5rem;
  position: fixed;
  left: var(--size-6);
  top: var(--size-4);
  width: 2.75rem;
  z-index: 10;
}

.drawer-toggle-label span {
  background: #fff;
  border-radius: 10px;
  height: 3px;
  margin: 3px 0;
  transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

  &:nth-of-type(1){
    width:50%;
  }
  &:nth-of-type(2){
    width:100%;
  }
  &:nth-of-type(3){
    width:75%;
  }
}
.drawer-toggle-label input[type="checkbox"]{
  display:none;
 }
.drawer-toggle-label input[type="checkbox"]:checked ~ span:nth-of-type(1) {
  transform-origin:bottom;
  transform: rotatez(45deg) translate(4px, 2px);
}
.drawer-toggle-label input[type="checkbox"]:checked ~ span:nth-of-type(2) {
  transform-origin:top;
  transform:rotatez(-45deg);
}
.drawer-toggle-label input[type="checkbox"]:checked ~ span:nth-of-type(3) {
  transform-origin:bottom;
  width:50%;
  transform: translate(11px, -4px) rotatez(45deg);
}


.drawer-toggle-label:has(input[type="checkbox"]:checked) ~ .site-nav {
  flex-direction: column;
  left: 0;
}

.site-nav {
  align-items: center;
  background-color: var(--brand);
  block-size: 100vh;
  box-shadow: 0px 4px 36px 12px rgb(52 129 117 / 76%);
  display: flex;
  font-family: var(--font-sans);
  left: -100%;
  padding: var(--size-8);
  position: fixed;
  transition: .3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  z-index: 9;
}


.site-nav ul {
  display: flex;
  flex-direction: column;
  font-family: var(--primary-font);
  font-size: 1.25rem;
  gap: var(--size-3);
  height: 100%;
  margin-inline-start: auto;
  margin-block-start: var(--size-8);
  text-transform: uppercase;

  & > li {
    font-family: var(--font-sans);
  }

  & a {
    color: #fff;
    display: block;
    padding: var(--size-4);
    text-decoration: none;
  }
}

@media screen and (min-width: 61rem) {
  .site-nav {
    background-color: #fff;
    box-shadow: none;
    left: initial;
    max-block-size: 7.375rem;
    padding: 0;
    position: relative;
  }
  .site-nav ul {
    align-items: center;
    margin-block-start: initial;
  }
  .site-nav ul a {
    color: var(--gray);
  }
}



.home-link {
  color: var(--gray-dark);
  font-family: var(--font-sans);
  inline-size: fit-content;
  line-height: 1;
  text-decoration: none;

  position: fixed;
  top: var(--size-4);
  right: var(--size-4);

  &:hover {
    text-decoration: underline;
  }
}





@media screen and (min-width: 61rem) {
  .drawer-toggle-label {
    display: none;
  }

  .site-nav ul {
    flex-direction: row;
    margin-inline: auto;
    padding-block: var(--size-6);
  }

  .home-link {
    left: var(--size-2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

}
